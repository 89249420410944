import React from "react";

const IconOpen = ( )=>{
  return (
    <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="3" fill="#091E42"/>
      <rect y="8.5" width="30" height="3" fill="#091E42"/>
      <rect y="17" width="30" height="3" fill="#091E42"/>
    </svg>
  )
}

export default IconOpen