import React, { useState } from "react";
import c from "./Header.module.scss";
import MainLogo from "../../ui/icons/MainLogo";
import Container from "../../ui/Container/Container";
import MainButton from "../../ui/buttons/MainButton/MainButton";
import { Link } from 'react-scroll'
import ToggleMenu from "./components/ToggleMenu/ToggleMenu";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import { linkSettings, menu } from "../../../configs/Menu";
import { IMenuItem } from "../../../types/main";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "./components/LangugeSwetcher/LanguageSwitcher";


const Header = () => {
  const [t] = useTranslation()
  const [isOpen, setIsOpen] = useState(false);

  const closeMobileMenu = () => {
    setIsOpen(false)
  }


  return (
    <header className={c.header}>
      {
        isOpen && <MobileMenu onClickItem={closeMobileMenu}/>
      }
      <Container>
        <div className={c.headerWrapper}>
          <MainLogo/>

          <div className={c.toggleMobile}>
            <ToggleMenu isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}/>
          </div>

          <div className={c.headerNav}>
            <ul>
              {
                menu.map((item: IMenuItem) => {
                  return (
                    <li key={item.name}>
                      <Link to={item.name} {...linkSettings}>
                        {item.title}
                      </Link>
                    </li>
                  )
                })
              }
            </ul>

            <Link to="reservation" {...linkSettings}>
              <MainButton theme="outline">
                {t('Бронювати')}
              </MainButton>
            </Link>
            <LanguageSwitcher/>
          </div>
        </div>
      </Container>
    </header>
  )
}

export default Header;