import React, { useLayoutEffect, useState } from 'react';
import './App.scss';
import Layout from "./components/layout/Layout/Layout";
import { Routes, Route } from "react-router-dom";
import RoomDetail from "./components/pages/RoomDetail/RoomDetail";
import api from "./api";
import { IMain } from "./types/main";
import GalleryDetail from "./components/pages/GalleryDetail/GalleryDetail";

const NoFound = () => <h1>Сторінки не знайдено</h1>;
const initialState = {
  gallery: [],
  about_us: [],
  rooms: [],
  services: [],
  sliders: [],
  location: {
    descr: '',
    title: '',
  },
  price_title: {
    descr: '',
    title: '',
  },
  room_title: {
    descr: '',
    title: '',
  },
  service_title: {
    descr: '',
    title: '',
  },
}

function App() {

  const [data, setData] = useState<IMain>(initialState);
  useLayoutEffect(() => {
    api.fetchHome().then((res) => {

      setData(res)
    })
  }, [])

  return (
    <div>
      <Routes>
        <Route element={<Layout data={data}/>} path="/">
          <Route element={<RoomDetail data={data}/>} path={"/rooms/:id"}/>
          <Route element={<GalleryDetail data={data}/>} path={"/gallery/:index"}/>
        </Route>
        <Route element={<Layout data={data}/>} path="/uk">
          <Route element={<RoomDetail data={data}/>} path={"/uk/rooms/:id"}/>
          <Route element={<GalleryDetail data={data}/>} path={"/uk/gallery/:img"}/>
        </Route>
        <Route path="*" element={<NoFound/>}/>
      </Routes>
    </div>
  );
}

export default App;
