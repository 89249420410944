import React, { FC, useState } from "react";
import Slider from "react-slick";
import c from "./Carousel.module.scss";

interface IProps {
  photos: Array<any>
}

const Carousel: FC<IProps> = ({ photos }) => {
  const [nav1, setNav1] = useState<any>(null)
  const [nav2, setNav2] = useState<any>(null)

  const multi = {
    initialSlide: 1,
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    focusOnSelect: true,
  };


  const single = {
    adaptiveHeight: true,
    dots: false,
    initialSlide: 1,
    draggable: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: false,
    focus: false,
    arrows: true,
  };

  return (
    <div className={c['double-carousel__carousel']} style={{ padding: '0 0 10px 0' }}>
      <div className={c["double-carousel__single"]}>
        <Slider
          {...single}
          asNavFor={nav2}
          ref={(slider1) => setNav1(slider1)}
        >
          {
            photos.map((photo, index) => {
              return (
                <div key={index}>
                  <div style={{ padding: '0' }}>
                    <div className={c['single__image']}
                         style={{ backgroundImage: `url('${photo.img}')` }}></div>
                  </div>
                </div>
              )
            })
          }

        </Slider>
      </div>
      <div className={c['double-carousel__multiple']} style={{padding: '0px 10px'}}>
        <Slider
          {...multi}
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2)}
        >
          {
            photos.map((photo, index) => {
              return (
                <div key={index}>
                  <div style={{ padding: '10px 5px 0 5px' }}>
                    <div className={c['multiple__image']}
                         style={{ backgroundImage: `url('${photo.img}')` }}></div>
                  </div>

                </div>
              )
            })
          }
        </Slider>
      </div>
    </div>
  )

}


export default Carousel;