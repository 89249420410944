import i18n, { allowedLanguages } from "../i18n";

const getLanguage = () => {
  if (allowedLanguages.includes(i18n.language)) {
    return i18n.language;
  } else {
    return '';
  }
};

export const language = getLanguage();