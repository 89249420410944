import React, { FC } from "react";
import c from "./Services.module.scss";
import Service from "./components/Service";
import Container from "../../ui/Container/Container";
import BlockHeader from "../../coomon/BlockHeader/BlockHeader";
import { ICommon, ITitles } from "../../../types/main";

interface IProps {
  service_title: ITitles;
  services: Array<ICommon>
}

const Services: FC<IProps> = ({ service_title, services }) => {
  return (
    <div className={c.services}>
      <Container>
        <BlockHeader title={service_title.title} description={service_title.descr}/>
        <ul>
          {
            services.map((item) => {
              return (
                <li key={item.id}>
                  <Service
                    image={item.img}
                    title={item.title}
                    description={item.descr}
                  />
                </li>
              )
            })
          }
        </ul>
      </Container>

    </div>
  )
}

export default Services