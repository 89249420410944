import React, { FC } from 'react';
import classNames from 'classnames';
import './ModalCore.scss';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import useModal from "./hooks/useModal";
import IconClose from "./components/icons/IconClose";

interface PropTypes {
  className?: string;
  classNameModal?: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

const modalRoot = document.createElement('div');
modalRoot.setAttribute('id', 'modal');
document.body.append(modalRoot);

const ModalCore: FC<PropTypes> = (props) => {
  const { children, className, onClose, classNameModal } = props;
  const classes = classNames(className, 'modal-core__content');
  const classesModal = classNames(classNameModal, 'modal-core');
  const $portalNode = document.getElementById('modal');
  const navigate = useNavigate();

  const handleClose = () => {
    if(onClose)
     onClose()
    else
      navigate(-1);
  }

  useModal(handleClose);

  if ($portalNode) {
    return ReactDOM.createPortal(
      <div className={classesModal}>
        <div className={classes}>
          <div className="modal-core__button-close" onClick={handleClose}>
            <IconClose/>
          </div>
          <div className="modal-core__body">{children}</div>
        </div>
        <div className="modal-core-close" onClick={handleClose} />
      </div>,
      $portalNode
    );
  }
  return null;
};

export default ModalCore;
