import React, { FC, useRef } from 'react';
import useOutsideAlerter from "./hooks/useOutsideClick";

interface IProps {
  onOutsideClick: (e: any) => void;
  children?: React.ReactNode;
}

const OutsideClick: FC<IProps> = (props) => {
  const { children, onOutsideClick } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onOutsideClick);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClick;
