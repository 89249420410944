import React, { FC } from "react";
import c from "./Service.module.scss";

interface IProps {
  image: string;
  title: string;
  description: string;
}


const Service:FC<IProps> = ({ image, title, description, }) => {
  return (
    <div className={c.services} >
        <img alt="" src={image} />
        <h5>{title}</h5>
        <div dangerouslySetInnerHTML={{__html: description}}></div>
    </div>
  )
}

export default Service