import React, { FC } from "react";
import { Element, } from 'react-scroll'
import c from "./Layout.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/Header";
import SearchForm from "../../blocks/SearchForm/SearchForm";
import About from "../../blocks/About/About";
import MainSlider from "../../blocks/MainSlider/MainSlider";
import Footer from "../Footer/Footer";
import Services from "../../blocks/Services/Services";
import Rooms from "../../blocks/Rooms/Rooms";
import { Outlet } from "react-router-dom";
import Location from "../../blocks/Location/Location";
import { IMain } from "../../../types/main";
import PhotoGallery from "../../blocks/PhotoGallery/PhotoGallery";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

interface IProps {
  data: IMain
}

const Layout: FC<IProps> = ({ data }) => {
  const { location, sliders, room_title, rooms, service_title, services, about_us } = data;
  const [t] = useTranslation();

  const trans = `${t('Глемпінг Буковель')}`;
  const description = `${t('meta_description')}`;

  return (
    <div className={c.layout}>
      <Helmet>
        <meta property="og:title" content={trans}/>
        <meta property="og:description" content={description}/>
        <meta name="keywords" content={trans}/>
        <meta name="description" content={description}/>
        <title>{t('Глемпінг Буковель')}</title>
      </Helmet>

      <Header/>
      <MainSlider sliders={sliders}/>
      <Element name="reservation">
        <SearchForm/>
      </Element>
      <Element name="about">
        <div className={c.layoutAbout}>
          <About about_us={about_us}/>
        </div>
      </Element>

      {
        data.gallery.length ?
          <Element name="gallery">
            <div className={c.layoutPhotoGallery}>
              <PhotoGallery photos={data.gallery}/>
            </div>
          </Element>
          : null
      }

      <Element name="rooms">
        <div className={c.layoutRooms}>
          <Rooms room_title={room_title} rooms={rooms}/>
        </div>
      </Element>
      <Element name="services">
        <div className={c.layoutServices}>
          <Services service_title={service_title} services={services}/>
        </div>
      </Element>
      <Element name="location">
        <div className={c.layoutLocation}>
          <Location location={location}/>
        </div>
      </Element>
      <Element name="contacts">
        <Footer/>
      </Element>
      <Outlet/>
    </div>
  )
}

export default Layout