import React, { FC } from "react";
import ModalCore from "../../ui/ModalCore/ModalCore";
import Carousel from "../../coomon/Carousel/Carousel";
import { IMain } from "../../../types/main";
import { useParams } from 'react-router-dom';

interface IProps {
  data: IMain
}



const RoomDetail: FC<IProps> = (props) => {
  // @ts-ignore
  const {id} = useParams<number>()
  const findCurrentRoomPhotos = (id: number, data: IMain) => {

    const currentRoom = data.rooms.find((item)=> item.id == id) || {photos: []};

    return currentRoom.photos || []
  }


  return (
    <ModalCore>
      <Carousel
        photos={findCurrentRoomPhotos(id, props.data)}/>
    </ModalCore>
  )
}

export default RoomDetail