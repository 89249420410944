import React from "react";
import c from "./Footer.module.scss";
import Container from "../../ui/Container/Container";
import { useTranslation } from "react-i18next";


const Footer = () => {
  const [t] = useTranslation()
  return (
    <footer style={{ backgroundImage: 'url(/images/footer-background.jpg)' }} className={c.footer}>
      <Container>

        <div className={c.footerWrapper}>
          <div className={c.footerInfo}>
            <div className={c.addressWrapper}>
              <div>
                <div className={c.receptionLabel}>{t('Бронювання глемпів')}</div>
                <div className={c.reservationPhone}>
                  <a href="tel: +380342591100">+380 342 591 100</a>
                </div>
              </div>

              <div className={c.email}>E-mail:
                <div>
                  <a href="mailto: web@bukovel.com"> web@bukovel.com</a>
                </div>
              </div>


              <div>
                <div className={c.receptionLabel}>{t('Бронювання для корпоративних груп')}</div>
                <div className={c.reservationPhone}>
                  <a href="tel: +380445029722">+38 044 502 97 22</a>
                </div>
                <div className={c.reservationPhone}>
                  <a href="tel: +380663319179">+38 066 331 91 79</a>
                </div>
              </div>

              <div className={c.email}>E-mail:
                <div>
                  <a href="mailto: sales_hotel@bukovel.com">sales_hotel@bukovel.com</a>
                </div>
              </div>


              <div className={c.address} style={{ maxWidth: 440 }}>{t('Адреса')}</div>


              <div className={c.footerName}>
                <div>Bukovel Glamping {new Date().getFullYear()}</div>
                <div>{t('Офіційний сайт')}</div>
              </div>
            </div>
          </div>
        </div>

      </Container>
    </footer>
  )
}

export default Footer