import React, { FC } from "react";
import ModalCore from "../../ui/ModalCore/ModalCore";
import Carousel from "../../coomon/Carousel/Carousel";
import { IMain } from "../../../types/main";

interface IProps {
  data: IMain
}

const GalleryDetail: FC<IProps> = (props) => {
  return (
    <ModalCore>
      <Carousel
        photos={props.data.gallery}/>
    </ModalCore>
  )
}

export default GalleryDetail