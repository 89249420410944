import i18n from "i18next"
import { Trans, useTranslation } from 'react-i18next'

export const menu = [{
  title: <Trans>Про готель</Trans>,
  name: 'about'
}, {
  title:   <Trans>Номери</Trans>,
  name: 'rooms'
}, {
  title:  <Trans>Сервіси</Trans>,
  name: 'services'
}, {
  title:  <Trans>Розташування</Trans>,
  name: 'location'
}, {
    title:  <Trans>Контакти</Trans>,
    name: 'contacts'
  }
]


const headerHeight = -84;
export const linkSettings = {
  spy: true,
  smooth: true,
  duration: 200,
  offset: headerHeight,
}