import React, { FC, ReactComponentElement } from "react";
import styles from "./MainButton.module.scss";
import classNames from "classnames/bind";


interface IProps {
  children?: any
  onClick?: () => void;
  theme?: 'default' | 'outline'
  size?: 'small' | 'large' | 'default'
  className?: string;
}
const cx = classNames.bind(styles);

const MainButton: FC<IProps> = ({children, className, size= 'default', theme= 'default', ...rest})=> {

  const classes = cx(styles.button, theme, size);

  return (
    <button {...rest} className={classes}>{children}</button>
  )
}

export default MainButton;