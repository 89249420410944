import React, { FC } from "react";
import c from "./MobileMenu.module.scss";
import { linkSettings, menu } from "../../../../../configs/Menu";
import { IMenuItem } from "../../../../../types/main";
import { Link } from "react-scroll";
import MainButton from "../../../../ui/buttons/MainButton/MainButton";
import MobileLanguageSwitcher from "../MobileLanguageSwitcher/MobileLanguageSwitcher";
import { useTranslation } from "react-i18next";


interface IProps {
  onClickItem: () => void;
}

const MobileMenu: FC<IProps> = ({onClickItem}) => {
  const [t] = useTranslation();
  return (
    <div className={c.mobileMenu}>
      <div className={c.mobileMenuWrapper}>

        <ul>
          <li>
            <MobileLanguageSwitcher/>
          </li>
          {
            menu.map((item: IMenuItem) => {
              return (
                <div key={item.name}  >
                  <li className={c.mobileMenuItem}>
                    <Link to={item.name} {...linkSettings} onClick={onClickItem}>
                      {item.title}
                    </Link>
                  </li>
                </div>

              )
            })
          }
        </ul>
        <div className={c.buttonWrapper}>
          <Link to="reservation" {...linkSettings}>
            <MainButton size="large" onClick={onClickItem}>{t('Бронювати')}</MainButton>
          </Link>
        </div>


      </div>
    </div>
  )
}

export default MobileMenu;