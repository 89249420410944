import React, { useState } from "react";
import c from "./SearchForm.module.scss";
import MainButton from "../../ui/buttons/MainButton/MainButton";
import Select from "../../ui/form/Select/Select";
import Datepicker from "../../ui/form/Datepicker/Datepicker";
import Container from "../../ui/Container/Container";
import { format, addDays } from 'date-fns'
import { useTranslation } from "react-i18next";


const SearchForm = () => {
  const [kids, setKids] = useState(0);
  const [adults, setAdults] = useState(2);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));
  const [t] = useTranslation();

  const generateCount = () => {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
      return {
        id: item,
        name: item,
      }
    })
  }


  const submitForm = () => {
    const start = format(startDate, 'yyyy-MM-dd')
    const end = format(endDate, 'yyyy-MM-dd')

    const generateKidsArray = () => {
      let kidsArray = '';
      for (let i = 0; i < kids; i++) {
        kidsArray = kidsArray + `kids[]=7&`
      }
      return kidsArray;
    }

    window.open(`https://bukovel24.com/uk/hotels/bukovel-glamping?arrival_date=${start}&departure_date=${end}&adults=${adults}&${generateKidsArray()}#search-form`, '_blank')
  }

  return (
    <div className={c['search-form']}>
      <Container>
        <div className={c['search-form-wrapper']}>
          <div className={c['search-form-fields']}>
            <Datepicker minDate={new Date()} selected={startDate} label={t("Дата заїзду")} onChange={(date: any) => {
              setStartDate(date);
              setEndDate(addDays(date, 1))
            }}/>
            <Datepicker minDate={addDays(startDate, 1)} selected={endDate} label={t("Дата виїзду")}
                        onChange={(date: any) => setEndDate(date)}/>
            <Select options={generateCount()} onChange={(e: any) => setKids(e.target.value)} value={kids}
                    labelKey={"name"} valueKey={"id"} label={t("Діти")}/>
            <Select options={generateCount()} onChange={(e: any) => setAdults(e.target.value)} value={adults}
                    labelKey={"name"} valueKey={"id"} label={t("Дорослі")}/>
          </div>

          <div className={c.searchButton}>
            <MainButton onClick={submitForm} size="large">{t('Бронювати')}</MainButton>

          </div>
        </div>
      </Container>
    </div>
  )
}

export default SearchForm;