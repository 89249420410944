import React, { FC } from "react";
import IconOpen from "../icons/IconOpen";
import IconClose from "../icons/IconClose";

interface IProps {
  isOpen: boolean;
  onClick: () => void;
}


const ToggleMenu: FC<IProps> = ({ isOpen, onClick }) => {
  return (
    <div onClick={onClick}>
      {
        isOpen ?
          <IconClose/>
          :
          <IconOpen/>
      }
    </div>
  )
}

export default ToggleMenu;