import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { language } from "./helpers/language";
import { initBackendLng } from "./helpers/network";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


initBackendLng(language);
console.log(language);
const basename = language === 'uk' ? '' : language

console.log(basename);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={basename}>
      <App/>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
