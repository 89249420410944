import React, { FC } from "react";
import c from "./MainSlider.module.scss";

interface IProps {
  sliders: Array<{
    img: string;
    new_id: string;
    title: string;
  }>
}


const MainSlider: FC<IProps> = ({ sliders }) => {
  const [firstItem = {
    img: '',
    new_id: '',
    title: '',
  }] = sliders

  return (
    <div className={c.mainSlider}>
      <div style={{ backgroundImage: `url(${firstItem.img || ''})` }} className={c.mainSliderImage}/>
    </div>
  )
}

export default MainSlider;