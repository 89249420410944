import React from "react";


const IconSelectArrow = () => {
  return (
    <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9258 1L7.96261 7.96317L0.999439 1" stroke="#6F7B8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default IconSelectArrow