import React, { FC } from "react";
import c from "./Location.module.scss";
import Container from "../../ui/Container/Container";


interface IProps {
  location: {
    descr: string
    title: string;
  }
}

const Location: FC<IProps> = ({ location }) => {
  return (
    <div className={c.location}>
      <Container>
        <div className={c.locationWrapper}>
          <div className={c.locationText}>
            <h3>{location.title}</h3>
            <div dangerouslySetInnerHTML={{ __html: location.descr }}/>
          </div>
          <a href="https://goo.gl/maps/5LPsJk9UvUUjosab9" target="_blank" className={c.locationImageWrapper}>

            <img src={'/images/location-mobile.jpg'} className={c.locationImageMobile}/>
            <img src={'/images/location.jpg'} className={c.locationImageDesktop}/>
          </a>
        </div>
      </Container>
    </div>
  )
}

export default Location;