import React, { FC } from "react";
import c from "./Rooms.module.scss";
import Slider from "react-slick";
import Container from "../../ui/Container/Container";
import { Link } from "react-router-dom";
import BlockHeader from "../../coomon/BlockHeader/BlockHeader";
import { IRoom, ITitles } from "../../../types/main";


let dragging = false;

interface IProps{
  rooms: Array<IRoom>
  room_title: ITitles
}


const Rooms: FC<IProps> = ({rooms, room_title}) => {

  const settings = {
    dots: false,
    infinite: true,
    draggable: true,
    slidesToShow: 2,
    speed: 500,
    arrows: true,
    beforeChange: () => dragging = true,
    afterChange: () => dragging = false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          // arrows: false,
          // dots: true,
          draggable: true,
          centerMode: false,
        }
      }]
  };


  return (
    <div className={c.room}>

      <Container>
        <BlockHeader title={room_title.title} description={room_title.descr}/>
        <div className={c.room__wrapper}>
          {rooms.length ? <div className={c.room__carousel}>
            <Slider {...settings}>
              {rooms.map((item: IRoom) => {
                return (
                  <Link to={`/rooms/${item.id}`}
                        key={item.id}
                        onClick={(e) => dragging && e.preventDefault()}>
                  <div  className={c.room__outer}>
                    <div className={c.room__item}>
                      <div className={c.roomImage} style={{ backgroundImage: `url('${item.img}')` }}></div>
                      <div className={c.room__text}>
                        <h2>{item.name}</h2>
                        <div className={c.room__descr} dangerouslySetInnerHTML={{ __html: item.descr }}></div>
                      </div>
                    </div>
                  </div>
                  </Link>
                )
              })}
            </Slider>
          </div> : null}
        </div>
      </Container>
    </div>
  )
}

export default Rooms;