import React, { FC, useState } from "react";
import DatePicker,  { ReactDatePickerProps, registerLocale } from "react-datepicker";
import c from "./Datepicker.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import IconCalendar from "./components/icons/IconCalendar";
import uk from 'date-fns/locale/uk';
import en from 'date-fns/locale/en-GB';
import { language } from "../../../../helpers/language";

registerLocale('uk', uk);
registerLocale('en', en);

interface IProps extends ReactDatePickerProps {
  label?: string | null;
}

const Datepicker: FC<IProps> = ({label, ...rest}) => {
  return (
    <div className={c.datepickerWrapper}>
      {label && <label>{label}</label>}
      <div className={c.datepickerMain}>
        <DatePicker locale={language || 'uk'} {...rest}  dateFormat="dd.MM.yyyy" />
        <IconCalendar/>
      </div>
    </div>
  );
};

export default Datepicker;