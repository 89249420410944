import React from "react";

const IconClose = ()=>{
  return (
    <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5" y="1" width="30" height="3" transform="rotate(45 5 1)" fill="#091E42"/>
      <rect x="3" y="22" width="30" height="3" transform="rotate(-45 3 22)" fill="#091E42"/>
    </svg>
  )
}

export default IconClose