import axios, { AxiosResponse, AxiosError, AxiosInstance } from 'axios';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'https://glamping.bukovel.com/api/v1',
  headers: {
    common: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  },
});

export const initBackendLng = (lng = 'uk') => {
  axiosInstance.defaults.headers['Accept-Language'] = lng;
};


export function fetchData<T>(url: string, options?: any): Promise<T> {
  return axiosInstance
    .get(url, options)
    .then((result: AxiosResponse) => result.data)
    .catch((error: AxiosError) => {
      throw error.response;
    });
};

