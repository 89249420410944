import React from "react";

const IconCalendar = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22 21H2V5H6V2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2C6.94891 2 7.13968 2.07902 7.28033 2.21967C7.42098 2.36032 7.5 2.55109 7.5 2.75V5H16.5V2.75C16.5 2.55109 16.579 2.36032 16.7197 2.21967C16.8603 2.07902 17.0511 2 17.25 2C17.4489 2 17.6397 2.07902 17.7803 2.21967C17.921 2.36032 18 2.55109 18 2.75V5H22V21ZM3.5 6.5V19.5H20.5V6.5H3.5ZM17 16H15.4V14.4H17V16ZM12.8 16H11.2V14.4H12.8V16ZM8.6 16H7V14.4H8.6V16ZM17 11.6H15.4V10H17V11.6ZM12.8 11.6H11.2V10H12.8V11.6ZM8.6 11.6H7V10H8.6V11.6Z"
        fill="#6F7B8C"/>
    </svg>
  )
}

export default IconCalendar