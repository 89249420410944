import {useEffect} from 'react';

const useModal = (goBack: () => void) => {
    const escapeByKeyboard = (e: any) => {
        if (e.key === 'Escape') goBack();
    };
    const MODEL_OPEN_STATE = 'modal--open'

    // TODO REFACTOR
    const applyStyle = () => {
        // @ts-ignore
        document.querySelector('html').classList.add(MODEL_OPEN_STATE)
        document.addEventListener('keyup', escapeByKeyboard);

    }
    const toDefault = () => {

        //@ts-ignore
        document.querySelector('html').classList.remove(MODEL_OPEN_STATE)
        document.addEventListener('keyup', escapeByKeyboard);
    }

    useEffect(() => {

        applyStyle()
        return () => {
            toDefault()


            document.removeEventListener('keyup', escapeByKeyboard);
        };
    }, []);
    return {};
};

export default useModal;
