import React from "react";
import c from "./MobileLanguageSwitcher.module.scss";
import { allowedLanguages } from "../../../../../i18n";
import classNames from "classnames/bind";

import { language } from "../../../../../helpers/language";
const cx = classNames.bind(c);


const MobileLanguageSwitcher = () => {

  const selectLanguage = (lng: string) => {
    window.location.href = `/${lng}`;
  }


  return (
    <ul className={c.mobileLanguageSwitcher}>

      {
        allowedLanguages.map((item)=>{
          const classes = cx(c.languageItem,  {active: item === language});
          return (
            <li className={classes} onClick={() => selectLanguage(item)}>
              <span>{item}</span>
            </li>
          )
        })
      }

    </ul>
  )
}

export default MobileLanguageSwitcher;
