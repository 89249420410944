import React, { FC, ReactComponentElement } from "react";
import c from "./Container.module.scss";

interface IProps {
  children: any;
}

const Container: FC<IProps> = ({ children }) => {
  return (
    <div className={c.container}>{
      children
    }</div>
  )
}

export default Container;