import React from "react";


const IconClose = () => {
  return (
    <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="23" cy="23" r="23" fill="#EBECF0"/>
      <rect x="16.75" y="15" width="20.1535" height="2.4739" transform="rotate(45 16.75 15)" fill="#5E6C84"/>
      <rect x="15" y="29.251" width="20.1535" height="2.4739" transform="rotate(-45 15 29.251)" fill="#5E6C84"/>
    </svg>

  )
}

export default IconClose;