import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationUK from './locales/uk/translation.json';

// the translations
const resources = {
  uk: {
    translation: translationUK,
  },
  en: {
    translation: translationEN,
  },
};

export const allowedLanguages = Object.keys(resources);

const options = {
  order: ['path'],
  lookupFromPathIndex: 0,
  excludeCacheFor: ['cimode'],
  htmlTag: document.documentElement,
};

const lngDetector = new LngDetector(null, options);

i18n
  .use(lngDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'uk',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
