import React, { FC } from "react";
import c from "./BlockHeader.module.scss";


interface IBlockHeader {
  description?: string;
  title: string;
}

const BlockHeader: FC<IBlockHeader> = ({ description, title }) => {
  return (
    <div className={c.blockHeader}>
      <h3 className={c.blockHeaderTitle}>{title}</h3>
      {description && <div dangerouslySetInnerHTML={{ __html: description }} className={c.blockHeaderDescription}></div>}
    </div>
  )

}

export default BlockHeader;