import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PhotoGallery.scss";
import BlockHeader from "../../coomon/BlockHeader/BlockHeader";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

let dragging = false;

interface IProps {
  photos: Array<{
    img: string,
    title: string | null,
    description: string | null
  }>
}

const PhotoGallery: FC<IProps> = ({ photos }) => {
  const navigate = useNavigate();
  const [mouseMoved, setMouseMoved] = useState(false);
  const [t] = useTranslation();

  const settings = {
    dots: true,
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    className: "room__center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    beforeChange: () => dragging = true,
    afterChange: () => dragging = false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          draggable: true,
          centerMode: false,
        }
      }]
  };

  const handleClick = (index: number) => {
    if (!mouseMoved) {
      navigate(`/gallery/${index}`)
    }
  };

  return (
    <div className="photo-gallery">
      <BlockHeader title={t('Фотогалерея')}/>
      <div className="photo-gallery-wrapper">
        <Slider {...settings}>
          {photos.map((item: any, index) => {
            return (
              <div className="photo-gallery-outer"
                   onMouseMove={() => setMouseMoved(true)}
                   onMouseDown={() => setMouseMoved(false)}
                   onMouseUp={() => handleClick(index)}
              >
                <div className="photo-gallery-item">
                  <img height={592} src={item.img} className="photo-gallery-image" alt={""}/>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default PhotoGallery;