import React from "react";

const IconDropdown = ()=> {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8868_315650)">
        <path d="M2.80745 1.20391L6.19745 4.72191L9.59245 1.19491C9.67878 1.10514 9.78243 1.03383 9.89713 0.985304C10.0118 0.936778 10.1352 0.912046 10.2597 0.912607C10.3843 0.913168 10.5074 0.93901 10.6217 0.988568C10.7359 1.03812 10.8389 1.11037 10.9244 1.20091C11.2944 1.59391 11.2924 2.20891 10.9174 2.59791L6.91945 6.75191C6.82623 6.84876 6.71445 6.92582 6.59078 6.97848C6.4671 7.03115 6.33408 7.05834 6.19966 7.05844C6.06524 7.05853 5.93218 7.03152 5.80844 6.97903C5.68469 6.92653 5.5728 6.84963 5.47945 6.75291L1.47045 2.59991C1.28991 2.41291 1.18885 2.16324 1.18848 1.90331C1.1881 1.64339 1.28845 1.39343 1.46845 1.20591C1.55499 1.11568 1.65886 1.04384 1.77383 0.994711C1.8888 0.945577 2.0125 0.920156 2.13752 0.919969C2.26255 0.919782 2.38633 0.944834 2.50144 0.993624C2.61656 1.04241 2.72064 1.11394 2.80745 1.20391Z" fill="#091E42"/>
      </g>
      <defs>
        <clipPath id="clip0_8868_315650">
          <rect width="11" height="7" fill="white" transform="translate(0.799805 0.5)"/>
        </clipPath>
      </defs>
    </svg>

  )
}

export default IconDropdown;