import React, { FC } from "react";
import c from "./About.module.scss";
import Container from "../../ui/Container/Container";
import { ICommon } from "../../../types/main";

interface IProps {
  about_us: Array<ICommon>
}


const About: FC<IProps> = ({about_us}) => {

  const [firstItem = {title: '', descr: '', img: ''}] = about_us;
  return (
    <div style={{ backgroundImage: `url(${firstItem.img || '/images/about-background.png'})` }} className={c.about}>
      <div className={c.aboutGradient}></div>
      <div className={c.aboutGradient}></div>
      <div className={c.aboutGradient}></div>
      <Container>
          <div className={c.aboutText}>
            <h2>{firstItem.title}</h2>
            <div dangerouslySetInnerHTML={{__html: firstItem.descr}}></div>
          </div>
      </Container>
    </div>
  )
}

export default About