import React, { useState } from "react";
import c from "./LanguageSwitcher.module.scss";
import OutsideClick from "../../../../ui/helpers/OutsideClick/OutsideClick";
import { allowedLanguages } from "../../../../../i18n";
import { language } from "../../../../../helpers/language";
import IconDropdown from "../icons/IconDropdown";


const LanguageSwitcher = () => {
  const selected = allowedLanguages.find(item => item === language);
  const items = allowedLanguages.filter(item => item !== language);
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const changeLanguage = (lng: string) => {
    window.location.href = `/${lng}`;
  };

  return (
    <div className={c.languageSwitcher}>
      <div className={c.languageSwitcherSelected} onClick={() => setIsOpen(!isOpen)}>
        {selected} <IconDropdown/>
      </div>
      <div className={c.languageSwitcherListWrapper}>
      <OutsideClick onOutsideClick={closeDropdown}>
        {isOpen && <ul className={c.languageSwitcherList}>
          {
            items.map((item) => {
              return (
                <li onClick={() => changeLanguage(item)}>{item}</li>
              )
            })
          }
        </ul>}
      </OutsideClick>
      </div>
    </div>
  )
}

export default LanguageSwitcher