import React from "react";


const MainLogo = () => {
  return (
    <svg width="238" height="33" viewBox="0 0 238 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8886_318985)">
        <path d="M131.766 32.6853H131.661C131.487 32.6853 131.349 32.5538 131.349 32.3862V9.83471C131.349 9.6671 131.487 9.53564 131.661 9.53564H131.766C131.941 9.53564 132.079 9.6671 132.079 9.83471V32.3829C132.079 32.5505 131.941 32.682 131.766 32.682V32.6853Z" fill="#172B4D"/>
        <path d="M164.536 16.8447H162.219C161.992 16.8447 161.815 17.0222 161.815 17.249V23.5228C161.815 25.212 160.579 26.3688 158.969 26.3688C157.358 26.3688 156.122 25.2087 156.122 23.5228V17.249C156.122 17.0222 155.945 16.8447 155.718 16.8447H153.451C153.175 16.8447 152.997 17.0222 152.997 17.249V23.5228C152.997 26.8223 155.593 29.343 158.969 29.343C162.344 29.343 164.94 26.8223 164.94 23.5228V17.2983C164.94 17.0222 164.762 16.8447 164.536 16.8447Z" fill="#3E9A36"/>
        <path d="M144.623 29.2345C143.575 29.2345 142.566 29.1096 141.603 28.8565C140.834 28.6561 140.193 28.4162 139.677 28.1335C139.467 28.0185 139.378 27.7622 139.48 27.5453L140.292 25.8002C140.4 25.5701 140.676 25.4682 140.9 25.5865C141.369 25.833 141.902 26.0401 142.493 26.211C143.262 26.4312 144.018 26.5429 144.761 26.5429C145.583 26.5429 146.164 26.4443 146.506 26.2471C146.848 26.0499 147.019 25.7772 147.019 25.4255C147.019 25.1363 146.884 24.9194 146.621 24.7748C146.355 24.6302 146.003 24.5217 145.56 24.4429C145.119 24.3673 144.636 24.2917 144.11 24.2161C143.585 24.1405 143.059 24.0386 142.526 23.9072C141.994 23.779 141.507 23.5851 141.067 23.3255C140.627 23.0658 140.272 22.7175 140.006 22.2771C139.739 21.8367 139.608 21.2649 139.608 20.5682C139.608 19.7926 139.831 19.109 140.282 18.5141C140.729 17.9226 141.379 17.4526 142.231 17.1108C143.082 16.769 144.1 16.5981 145.287 16.5981C146.122 16.5981 146.976 16.6902 147.84 16.8709C148.521 17.0155 149.112 17.2094 149.615 17.4559C149.839 17.5644 149.934 17.8306 149.829 18.0573L149.027 19.7696C148.922 19.9963 148.649 20.0949 148.425 19.9832C147.965 19.7498 147.502 19.5822 147.035 19.4836C146.434 19.3555 145.852 19.2897 145.29 19.2897C144.498 19.2897 143.923 19.3982 143.558 19.6085C143.177 19.832 142.996 20.1179 143.013 20.4728C143.026 20.7555 143.2 20.9954 143.446 21.1334C143.709 21.278 144.051 21.3931 144.472 21.4785C144.912 21.5705 145.395 21.6494 145.921 21.7184C146.447 21.7874 146.973 21.8893 147.505 22.0273C148.038 22.1654 148.521 22.3593 148.955 22.609C149.388 22.8588 149.74 23.2006 150.016 23.6344C150.289 24.0682 150.427 24.6335 150.427 25.3335C150.427 26.0926 150.2 26.7664 149.743 27.3514C149.286 27.9363 148.629 28.3964 147.771 28.7317C146.914 29.0669 145.869 29.2345 144.636 29.2345H144.623Z" fill="#3E9A36"/>
        <path d="M183.604 16.6014C184.576 16.6014 185.441 16.7953 186.193 17.1831C186.946 17.5709 187.537 18.1625 187.971 18.9611C188.405 19.7597 188.622 20.7818 188.622 22.0273V28.6002C188.622 28.85 188.421 29.0504 188.172 29.0504H185.516C185.266 29.0504 185.066 28.85 185.066 28.6002V22.5729C185.066 21.5837 184.862 20.8541 184.451 20.3841C184.041 19.9142 183.462 19.6775 182.72 19.6775C182.204 19.6775 181.74 19.7958 181.329 20.0325C180.919 20.2691 180.6 20.6207 180.373 21.094C180.146 21.5639 180.031 22.1654 180.031 22.895V28.6002C180.031 28.85 179.831 29.0504 179.581 29.0504H176.926C176.676 29.0504 176.475 28.85 176.475 28.6002V22.5729C176.475 21.5837 176.275 20.8541 175.871 20.3841C175.466 19.9142 174.895 19.6775 174.149 19.6775C173.633 19.6775 173.169 19.7958 172.758 20.0325C172.348 20.2691 172.029 20.6207 171.802 21.094C171.575 21.5639 171.46 22.1654 171.46 22.895V28.6002C171.46 28.85 171.26 29.0504 171.01 29.0504H168.355C168.105 29.0504 167.904 28.85 167.904 28.6002V17.2324C167.904 16.9827 168.105 16.7822 168.355 16.7822H170.852C171.102 16.7822 171.302 16.9827 171.302 17.2324V20.1343L170.665 19.155C171.089 18.3202 171.694 17.6827 172.479 17.2521C173.261 16.8183 174.155 16.6014 175.157 16.6014C176.281 16.6014 177.267 16.8874 178.112 17.4559C178.957 18.0245 179.515 18.8954 179.788 20.0653L178.533 19.7235C178.943 18.7672 179.601 18.0047 180.504 17.4428C181.408 16.8808 182.447 16.5981 183.617 16.5981L183.604 16.6014Z" fill="#3E9A36"/>
        <path d="M207.525 16.6014C208.498 16.6014 209.363 16.7953 210.115 17.1831C210.868 17.5709 211.459 18.1625 211.893 18.9611C212.327 19.7597 212.544 20.7818 212.544 22.0273V28.6002C212.544 28.85 212.343 29.0504 212.094 29.0504H209.438C209.188 29.0504 208.988 28.85 208.988 28.6002V22.5729C208.988 21.5837 208.784 20.8541 208.373 20.3841C207.963 19.9142 207.384 19.6775 206.641 19.6775C206.125 19.6775 205.662 19.7958 205.251 20.0325C204.84 20.2691 204.522 20.6207 204.295 21.094C204.068 21.5639 203.953 22.1654 203.953 22.895V28.6002C203.953 28.85 203.753 29.0504 203.503 29.0504H200.847C200.598 29.0504 200.397 28.85 200.397 28.6002V22.5729C200.397 21.5837 200.197 20.8541 199.792 20.3841C199.388 19.9142 198.816 19.6775 198.07 19.6775C197.554 19.6775 197.091 19.7958 196.68 20.0325C196.269 20.2691 195.951 20.6207 195.724 21.094C195.497 21.5639 195.382 22.1654 195.382 22.895V28.6002C195.382 28.85 195.182 29.0504 194.932 29.0504H192.276C192.027 29.0504 191.826 28.85 191.826 28.6002V17.2324C191.826 16.9827 192.027 16.7822 192.276 16.7822H194.774C195.024 16.7822 195.224 16.9827 195.224 17.2324V20.1343L194.587 19.155C195.011 18.3202 195.615 17.6827 196.401 17.2521C197.183 16.8183 198.077 16.6014 199.079 16.6014C200.203 16.6014 201.189 16.8874 202.034 17.4559C202.878 18.0245 203.437 18.8954 203.71 20.0653L202.454 19.7235C202.865 18.7672 203.523 18.0047 204.426 17.4428C205.33 16.8808 206.369 16.5981 207.539 16.5981L207.525 16.6014Z" fill="#3E9A36"/>
        <path d="M221.916 29.2345C220.516 29.2345 219.291 28.9617 218.232 28.4129C217.174 27.864 216.359 27.118 215.781 26.165C215.202 25.2152 214.913 24.1307 214.913 22.9147C214.913 21.6987 215.199 20.5912 215.768 19.6414C216.336 18.6916 217.115 17.9456 218.104 17.4066C219.093 16.8676 220.211 16.5981 221.456 16.5981C222.702 16.5981 223.74 16.8545 224.707 17.3639C225.673 17.8733 226.435 18.6029 226.997 19.5526C227.559 20.5024 227.842 21.6395 227.842 22.9607C227.842 23.0363 227.825 23.2762 227.809 23.5029C227.793 23.7297 227.595 23.9203 227.359 23.9203H217.808V21.8466H225.903L224.536 22.4611C224.536 21.8236 224.408 21.2682 224.148 20.7949C223.888 20.325 223.533 19.9536 223.077 19.6874C222.62 19.4212 222.087 19.2864 221.479 19.2864C220.871 19.2864 220.336 19.4212 219.872 19.6874C219.409 19.9536 219.047 20.325 218.788 20.8048C218.528 21.2846 218.4 21.8499 218.4 22.5039V23.0527C218.4 23.7231 218.548 24.3114 218.844 24.8208C219.139 25.3302 219.557 25.718 220.099 25.9842C220.638 26.2504 221.272 26.3851 222.002 26.3851C222.656 26.3851 223.231 26.2866 223.724 26.0894C224.095 25.9415 224.447 25.7344 224.772 25.4715C224.953 25.3236 225.219 25.3499 225.377 25.5208L226.685 26.9405C226.846 27.1147 226.849 27.3908 226.679 27.5584C226.169 28.0514 225.561 28.4392 224.851 28.7251C224 29.0669 223.021 29.2378 221.91 29.2378L221.916 29.2345Z" fill="#3E9A36"/>
        <path d="M230.215 28.6034V17.2356C230.215 16.9859 230.415 16.7854 230.665 16.7854H233.163C233.413 16.7854 233.613 16.9859 233.613 17.2356V20.2526L233.133 19.2502C233.498 18.3826 234.083 17.7253 234.888 17.2783C235.585 16.8905 236.41 16.6704 237.366 16.6178C237.619 16.6046 237.83 16.815 237.83 17.068V19.4047C237.83 19.6643 237.613 19.8681 237.353 19.8549C237.215 19.8483 237.09 19.8418 237.031 19.8418C236.058 19.8418 235.273 20.1178 234.671 20.6732C234.07 21.2286 233.771 22.0897 233.771 23.2629V28.6034C233.771 28.8532 233.57 29.0536 233.321 29.0536H230.665C230.415 29.0536 230.215 28.8532 230.215 28.6034Z" fill="#3E9A36"/>
        <path d="M35.103 16.299C33.6372 16.299 32.349 16.8051 31.3137 17.6365V11.8754C31.3137 11.6487 31.1363 11.4712 30.9095 11.4712C30.8339 11.4712 30.7583 11.4975 30.709 11.5205L28.4611 12.5294C28.2837 12.5787 28.1851 12.7332 28.1851 12.9074V22.7831C28.1851 26.4475 31.0147 29.2738 34.702 29.2738C38.3894 29.2738 41.219 26.4442 41.219 22.7831C41.219 19.122 38.5406 16.2924 35.1063 16.2924M34.702 26.2963C32.8091 26.2963 31.3433 24.8568 31.3433 22.7864C31.3433 20.7159 32.8091 19.2765 34.702 19.2765C36.595 19.2765 38.0608 20.7159 38.0608 22.7864C38.0608 24.8568 36.595 26.2963 34.702 26.2963Z" fill="#172B4D"/>
        <path d="M62.5113 11.4745C62.4357 11.4745 62.3601 11.5008 62.3075 11.5238L60.0596 12.5327C59.8822 12.582 59.7803 12.7365 59.7803 12.9106V28.6231C59.7803 28.8499 59.9577 29.0273 60.1845 29.0273H62.4587C62.7348 29.0273 62.9122 28.8499 62.9122 28.6231V11.8754C62.9122 11.6487 62.7348 11.4712 62.508 11.4712" fill="#172B4D"/>
        <path d="M55.6921 16.7559H53.3686C53.1419 16.7559 52.9644 16.9333 52.9644 17.1601V23.4503C52.9644 25.1428 51.7254 26.3062 50.1085 26.3062C48.4916 26.3062 47.2526 25.1428 47.2526 23.4503V17.1601C47.2526 16.9333 47.0751 16.7559 46.8483 16.7559H44.5741C44.2948 16.7559 44.1206 16.9333 44.1206 17.1601V23.4503C44.1206 26.7598 46.7235 29.287 50.1085 29.287C53.4935 29.287 56.0964 26.7598 56.0964 23.4503V17.2127C56.0964 16.9333 55.9189 16.7591 55.6921 16.7591" fill="#172B4D"/>
        <path d="M101.009 16.7559H98.6097C98.3566 16.7559 98.2054 16.8807 98.1561 17.0352L94.8204 25.6982L91.4354 17.0352C91.3861 16.884 91.2316 16.7559 91.0575 16.7559H88.4809C88.2541 16.7559 88.0767 16.907 88.0767 17.1108C88.0767 17.2127 88.126 17.3376 88.1523 17.4131L92.6481 28.7546C92.6974 28.9321 92.8518 29.034 93.0523 29.034H96.4373C96.6148 29.034 96.7397 28.9321 96.8153 28.7809L101.337 17.4131C101.364 17.3376 101.413 17.2127 101.413 17.1108C101.413 16.907 101.236 16.7559 101.009 16.7559Z" fill="#172B4D"/>
        <path d="M121.852 11.4745C121.776 11.4745 121.7 11.5008 121.648 11.5238L119.4 12.5327C119.222 12.582 119.121 12.7365 119.121 12.9106V28.5738C119.121 28.8531 119.298 29.0273 119.525 29.0273H121.848C122.075 29.0273 122.253 28.8499 122.253 28.6231V11.8754C122.253 11.6487 122.075 11.4712 121.848 11.4712" fill="#172B4D"/>
        <path d="M109.37 16.2987C105.784 16.2987 102.955 19.1283 102.955 22.7894C102.955 26.4505 105.886 29.2801 109.751 29.2801C111.696 29.2801 113.514 28.5209 114.727 27.2852C114.776 27.2097 114.828 27.1341 114.828 27.0059C114.828 26.904 114.779 26.8021 114.704 26.7265L113.29 25.2871C113.215 25.2115 113.113 25.1589 113.011 25.1589C112.886 25.1589 112.784 25.2082 112.709 25.2871C111.9 25.9444 111.042 26.273 109.777 26.273C108.058 26.273 106.645 25.3627 106.165 23.9232H115.308C115.588 23.9232 115.814 23.6965 115.814 23.4697V22.7861C115.814 19.1217 112.958 16.2954 109.373 16.2954M106.165 21.5241C106.619 20.0584 107.782 19.2499 109.373 19.2499C110.964 19.2499 112.176 20.1603 112.554 21.5241H106.162H106.165Z" fill="#172B4D"/>
        <path d="M66.9318 22.7404L71.076 17.4624C71.1516 17.3868 71.2271 17.2586 71.2271 17.1337C71.2271 16.907 71.0497 16.7295 70.8229 16.7295H68.2201C67.9933 16.7295 67.8651 16.8544 67.7665 17.0088L63.4975 22.5399C63.3463 22.7174 63.32 22.9178 63.4975 23.0953L67.9177 28.7776C68.0196 28.9024 68.1445 29.0306 68.3219 29.0306H71.1023C71.329 29.0306 71.5065 28.8531 71.5065 28.6264C71.5065 28.5015 71.4309 28.3996 71.3553 28.324L66.9351 22.7404H66.9318Z" fill="#172B4D"/>
        <path d="M79.8409 16.2988C76.078 16.2988 73.1465 19.1284 73.1465 22.7895C73.1465 26.4506 76.078 29.2802 79.8409 29.2802C83.6039 29.2802 86.5354 26.4506 86.5354 22.7895C86.5354 19.1284 83.6039 16.2988 79.8409 16.2988ZM79.8409 26.3027C77.8461 26.3027 76.2785 24.8633 76.2785 22.7928C76.2785 20.7224 77.8461 19.2829 79.8409 19.2829C81.8358 19.2829 83.4034 20.7224 83.4034 22.7928C83.4034 24.8633 81.8358 26.3027 79.8409 26.3027Z" fill="#172B4D"/>
        <path d="M15.4368 14.3043C15.4302 10.1798 12.0715 6.82764 7.94703 6.82764C4.60144 6.82764 1.76197 9.03283 0.802332 12.0629C2.4817 12.0629 7.94703 12.0761 7.94703 12.0761C9.18273 12.0794 10.1851 13.0817 10.1851 14.3174C10.1851 14.7348 10.1654 18.1888 10.1654 19.5757C11.263 19.579 14.1091 19.5527 15.4237 19.5527C16.6626 19.5527 17.665 20.5584 17.665 21.7973C17.665 23.0363 16.6594 24.0387 15.4204 24.0387C14.8452 24.0387 5.69254 24.0453 5.69254 24.0453C5.69254 24.0453 5.69254 17.9424 5.69254 16.5555C4.62445 16.5555 1.81127 16.5489 0.450684 16.5489C0.450684 18.2579 0.45397 20.3645 0.457256 22.3297L0.467116 29.2674L7.77942 29.2773C10.6419 29.2805 13.7279 29.2838 15.4171 29.2838C19.5481 29.2838 22.9069 25.9251 22.9069 21.7941C22.9069 17.663 19.5547 14.3108 15.4302 14.3043" fill="#3E9A36"/>
        <path d="M72.8869 6.81074C72.7817 6.86661 72.6766 6.91262 72.5714 6.94877C72.2756 7.05065 71.9733 7.09995 71.6643 7.09995C71.3554 7.09995 71.0432 7.04737 70.777 6.94549C70.5075 6.84361 70.2742 6.69243 70.077 6.49524C69.8798 6.29806 69.7286 6.06801 69.6202 5.80181C69.515 5.53561 69.4592 5.2464 69.4592 4.92762C69.4592 4.60883 69.5117 4.30977 69.6202 4.04357C69.7254 3.77736 69.8798 3.54731 70.077 3.35341C70.2742 3.15952 70.5075 3.01163 70.7803 2.90646C71.0531 2.80458 71.3521 2.752 71.6841 2.752C72.016 2.752 72.3446 2.81444 72.6338 2.94261C72.8836 3.05106 73.1169 3.20881 73.3339 3.41257C73.3963 3.47173 73.4883 3.47501 73.5508 3.41586L74.3329 2.69284C74.3954 2.63369 74.4019 2.53181 74.3428 2.46936C74.0339 2.14401 73.6625 1.89095 73.2287 1.71348C72.7456 1.51301 72.2033 1.41113 71.6019 1.41113C71.0596 1.41113 70.5601 1.49658 70.1066 1.66747C69.653 1.83837 69.2587 2.08485 68.9235 2.40035C68.5882 2.71585 68.3253 3.0905 68.1413 3.51774C67.9573 3.94497 67.8652 4.41493 67.8652 4.92433C67.8652 5.43373 67.9573 5.90369 68.1413 6.33092C68.3253 6.75816 68.585 7.12953 68.9169 7.44831C69.2488 7.76381 69.6399 8.01029 70.0901 8.18118C70.5404 8.35208 71.0334 8.43753 71.569 8.43753C72.0554 8.43753 72.5418 8.36194 73.0282 8.21405C73.5179 8.06616 73.9451 7.85254 74.3132 7.57319V4.97363C74.3132 4.88818 74.2442 4.81588 74.1555 4.81588H73.0348C72.9493 4.81588 72.877 4.88489 72.877 4.97363V6.80746L72.8869 6.81074Z" fill="#172B4D"/>
        <path d="M75.6704 8.1649V1.27982C75.6704 1.19437 75.7394 1.12207 75.8282 1.12207H77.0277C77.1132 1.12207 77.1855 1.19109 77.1855 1.27982V8.16818C77.1855 8.25363 77.1164 8.32593 77.0277 8.32593H75.8282C75.7427 8.32593 75.6704 8.25692 75.6704 8.16818V8.1649Z" fill="#172B4D"/>
        <path d="M82.5522 3.58688C82.1118 3.21223 81.494 3.0249 80.6987 3.0249C80.2846 3.0249 79.877 3.08077 79.4761 3.18922C79.1277 3.28453 78.8254 3.4127 78.5658 3.57702C78.4967 3.61975 78.477 3.70848 78.5132 3.78078L78.9108 4.55309C78.9536 4.63525 79.0554 4.66483 79.131 4.61554C79.2888 4.51366 79.4695 4.42821 79.6766 4.36248C79.9461 4.27375 80.2188 4.23102 80.4982 4.23102C80.9057 4.23102 81.2081 4.32304 81.4052 4.5038C81.6024 4.68455 81.701 4.93761 81.701 5.25968H80.4982C79.9691 5.25968 79.5385 5.32541 79.2066 5.46015C78.878 5.59161 78.6348 5.77565 78.4836 6.00898C78.3324 6.24232 78.2568 6.51509 78.2568 6.82402C78.2568 7.13294 78.3357 7.38929 78.4902 7.62919C78.6446 7.8691 78.8681 8.05643 79.1606 8.19775C79.4531 8.33578 79.8015 8.40479 80.209 8.40479C80.6691 8.40479 81.0437 8.31606 81.3362 8.14188C81.5334 8.02357 81.6879 7.86253 81.8029 7.66863V8.16817C81.8029 8.25362 81.8719 8.32592 81.9606 8.32592H83.0649C83.1503 8.32592 83.2226 8.2569 83.2226 8.16817V5.34512C83.2226 4.54981 83.0024 3.96482 82.5621 3.58688H82.5522ZM81.2606 7.20196C81.0602 7.32027 80.8334 7.38271 80.5803 7.38271C80.3141 7.38271 80.1071 7.32684 79.9559 7.21182C79.8047 7.10008 79.7292 6.94562 79.7292 6.75172C79.7292 6.57754 79.7949 6.43293 79.9296 6.31462C80.0611 6.1996 80.3076 6.14044 80.6625 6.14044H81.701V6.67284C81.609 6.90618 81.4644 7.08365 81.2639 7.20196H81.2606Z" fill="#172B4D"/>
        <path d="M93.1279 4.02713C92.9438 3.68863 92.6908 3.43557 92.372 3.27125C92.0532 3.10693 91.6851 3.02477 91.271 3.02477C90.7715 3.02477 90.3311 3.14308 89.9466 3.38299C89.7264 3.52102 89.5424 3.68534 89.3912 3.87596C89.2696 3.67877 89.1151 3.51445 88.9278 3.38628C88.5696 3.14308 88.1489 3.02148 87.6724 3.02148C87.2451 3.02148 86.8639 3.1135 86.532 3.29754C86.3381 3.406 86.1738 3.54403 86.0325 3.70835V3.25482C86.0325 3.16937 85.9634 3.09707 85.8747 3.09707H84.7442C84.6587 3.09707 84.5864 3.16609 84.5864 3.25482V8.16147C84.5864 8.24691 84.6554 8.31921 84.7442 8.31921H85.9437C86.0292 8.31921 86.1015 8.2502 86.1015 8.16147V5.69664C86.1015 5.38443 86.1508 5.13137 86.2461 4.9309C86.3447 4.73043 86.4794 4.57925 86.6536 4.48066C86.8278 4.38207 87.025 4.32948 87.2451 4.32948C87.5606 4.32948 87.8071 4.43136 87.978 4.63184C88.1489 4.83231 88.2344 5.14452 88.2344 5.56518V8.16475C88.2344 8.2502 88.3034 8.3225 88.3921 8.3225H89.5917C89.6771 8.3225 89.7494 8.25349 89.7494 8.16475V5.69993C89.7494 5.38772 89.7987 5.13466 89.894 4.93419C89.9926 4.73372 90.1273 4.58254 90.3015 4.48395C90.4757 4.38535 90.6729 4.33277 90.8931 4.33277C91.2086 4.33277 91.4551 4.43465 91.6292 4.63512C91.8034 4.8356 91.8922 5.14781 91.8922 5.56847V8.16804C91.8922 8.25349 91.9612 8.32579 92.0499 8.32579H93.2495C93.3349 8.32579 93.4072 8.25677 93.4072 8.16804V5.33513C93.4072 4.80602 93.3152 4.36892 93.1311 4.03042L93.1279 4.02713Z" fill="#172B4D"/>
        <path d="M100.105 4.29675C99.8749 3.89581 99.566 3.5836 99.1716 3.36012C98.7805 3.13664 98.3369 3.0249 97.8472 3.0249C97.3871 3.0249 96.9927 3.1235 96.6641 3.3174C96.4899 3.41927 96.3387 3.55073 96.2073 3.70848V3.26153C96.2073 3.17608 96.1382 3.10378 96.0495 3.10378H94.919C94.8335 3.10378 94.7612 3.17279 94.7612 3.26153V10.0513C94.7612 10.1367 94.8302 10.209 94.919 10.209H96.1185C96.204 10.209 96.2763 10.14 96.2763 10.0513V7.77051C96.3979 7.90854 96.5392 8.02357 96.6936 8.1123C97.0223 8.3062 97.4101 8.40479 97.8472 8.40479C98.3401 8.40479 98.7805 8.29634 99.1716 8.07615C99.5627 7.85596 99.8749 7.54703 100.105 7.1428C100.335 6.74186 100.45 6.26533 100.45 5.71649C100.45 5.16766 100.335 4.70098 100.105 4.30004V4.29675ZM98.7378 6.48552C98.6195 6.70242 98.4585 6.87003 98.2613 6.98505C98.0641 7.10008 97.8406 7.15924 97.5876 7.15924C97.3345 7.15924 97.1077 7.10008 96.9073 6.98505C96.7068 6.87003 96.549 6.70242 96.4307 6.48552C96.3157 6.26861 96.2566 6.01227 96.2566 5.7132C96.2566 5.41414 96.3157 5.15122 96.4307 4.93761C96.5458 4.72399 96.7068 4.55967 96.9073 4.44136C97.1077 4.32633 97.3345 4.26717 97.5876 4.26717C97.8406 4.26717 98.0641 4.32633 98.2613 4.44136C98.4585 4.55638 98.6162 4.72399 98.7378 4.93761C98.8561 5.15122 98.9186 5.41085 98.9186 5.7132C98.9186 6.01556 98.8594 6.26861 98.7378 6.48552Z" fill="#172B4D"/>
        <path d="M102.218 2.37429C101.939 2.37429 101.712 2.29212 101.538 2.13109C101.364 1.97005 101.275 1.76958 101.275 1.52967C101.275 1.28976 101.364 1.08929 101.538 0.928255C101.712 0.767219 101.939 0.685059 102.218 0.685059C102.497 0.685059 102.724 0.760647 102.898 0.911822C103.073 1.063 103.161 1.26018 103.161 1.50009C103.161 1.75315 103.076 1.96019 102.905 2.12452C102.734 2.28884 102.504 2.371 102.221 2.371L102.218 2.37429ZM101.462 8.16827V3.25834C101.462 3.17289 101.531 3.10059 101.62 3.10059H102.82C102.905 3.10059 102.977 3.1696 102.977 3.25834V8.16498C102.977 8.25043 102.908 8.32273 102.82 8.32273H101.62C101.535 8.32273 101.462 8.25371 101.462 8.16498V8.16827Z" fill="#172B4D"/>
        <path d="M109.439 4.02726C109.251 3.68876 108.995 3.43571 108.666 3.27138C108.341 3.10706 107.97 3.0249 107.556 3.0249C107.109 3.0249 106.711 3.12021 106.363 3.30754C106.156 3.41927 105.978 3.56388 105.83 3.73149V3.26153C105.83 3.17608 105.761 3.10378 105.673 3.10378H104.542C104.457 3.10378 104.384 3.17279 104.384 3.26153V8.16817C104.384 8.25362 104.453 8.32592 104.542 8.32592H105.742C105.827 8.32592 105.899 8.2569 105.899 8.16817V5.74278C105.899 5.42071 105.952 5.15451 106.06 4.94747C106.166 4.74042 106.317 4.58596 106.507 4.48736C106.698 4.38877 106.915 4.33619 107.151 4.33619C107.487 4.33619 107.75 4.43807 107.934 4.63854C108.118 4.83901 108.21 5.15122 108.21 5.57189V8.17146C108.21 8.2569 108.279 8.32921 108.367 8.32921H109.567C109.652 8.32921 109.725 8.26019 109.725 8.17146V5.33855C109.725 4.80944 109.629 4.37234 109.442 4.03384L109.439 4.02726Z" fill="#172B4D"/>
        <path d="M115.078 3.25824V3.76435C114.92 3.56716 114.733 3.40613 114.516 3.2911C114.181 3.11364 113.79 3.0249 113.353 3.0249C112.866 3.0249 112.429 3.13007 112.038 3.33711C111.647 3.54416 111.331 3.83665 111.098 4.21459C110.861 4.59253 110.743 5.03949 110.743 5.54888C110.743 6.05828 110.861 6.49538 111.098 6.87332C111.335 7.25126 111.647 7.54703 112.038 7.75737C112.429 7.9677 112.869 8.07286 113.353 8.07286C113.793 8.07286 114.181 7.98084 114.516 7.80009C114.7 7.7015 114.861 7.57333 115.002 7.41558V7.64563C115.002 8.11887 114.881 8.47381 114.638 8.71372C114.394 8.95363 114.007 9.07194 113.468 9.07194C113.139 9.07194 112.804 9.01936 112.462 8.9109C112.189 8.82546 111.953 8.71372 111.759 8.58226C111.683 8.52968 111.581 8.55926 111.535 8.63813L111.088 9.44659C111.049 9.51561 111.069 9.60434 111.131 9.65035C111.414 9.84754 111.759 10.002 112.16 10.1072C112.616 10.2255 113.09 10.2879 113.583 10.2879C114.526 10.2879 115.252 10.0579 115.758 9.59777C116.264 9.13767 116.514 8.42451 116.514 7.45173V3.25824C116.514 3.17279 116.445 3.10049 116.356 3.10049H115.236C115.15 3.10049 115.078 3.16951 115.078 3.25824ZM114.845 6.21603C114.73 6.40993 114.565 6.5611 114.358 6.66627C114.151 6.77144 113.915 6.82731 113.648 6.82731C113.382 6.82731 113.146 6.77472 112.939 6.66627C112.731 6.5611 112.567 6.40993 112.449 6.21603C112.331 6.02213 112.268 5.79865 112.268 5.5456C112.268 5.29254 112.327 5.06249 112.449 4.87188C112.567 4.68126 112.731 4.53338 112.939 4.42492C113.146 4.31976 113.382 4.26389 113.648 4.26389C113.915 4.26389 114.151 4.31647 114.358 4.42492C114.565 4.53009 114.726 4.68126 114.845 4.87188C114.96 5.06249 115.019 5.28925 115.019 5.5456C115.019 5.80194 114.96 6.02213 114.845 6.21603Z" fill="#172B4D"/>
      </g>
      <defs>
        <clipPath id="clip0_8886_318985">
          <rect width="237.376" height="32" fill="white" transform="translate(0.457031 0.685059)"/>
        </clipPath>
      </defs>
    </svg>

  )
}
export default  MainLogo;