import React, { FC } from "react";
import c from "./Select.module.scss";
import IconSelectArrow from "./components/icons/IconSelectArrow";

interface IProps {
  options: Array<any>
  value: string | number;
  valueKey: string;
  labelKey: string;
  label?: string | null;
  onChange: (e: any) => void;
}

const Select: FC<IProps> = ({ options, value, valueKey, labelKey, label, ...rest }) => {
  return (
    <div className={c.selectWrapper}>
      {label && <label>{label}</label>}
      <div className={c.selectMain}>
        <select className={c.select} value={value} {...rest}>
          {
            options.map((item, index) => {
              return (
                <option
                  key={index}
                  selected={String(item[valueKey || 'value']) === String(value)}>{item[labelKey || 'label']}</option>
              )
            })
          }
        </select>
        <IconSelectArrow/>
      </div>

    </div>

  )
}

export default Select;